var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-absolute" },
    [
      _c("b-modal", {
        ref: "personalization-editor-modal",
        attrs: {
          id: "personalization-editor-modal",
          centered: "",
          size: "lg",
          "footer-class": "flex-nowrap",
          "no-close-on-backdrop": ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("settings.page.context.title")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("div", { staticClass: "m-4" }, [
                  _vm.showAddContextSection
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            { staticClass: "mb-4" },
                            [
                              _c("b-col", { attrs: { cols: "4" } }, [
                                _c("label", { attrs: { for: "value-name" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("modals.createContext.operation")
                                      ) +
                                      " "
                                  )
                                ])
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c("b-form-group", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function({
                                            ariaDescribedbyAction
                                          }) {
                                            return _vm._l(
                                              _vm.availableActions,
                                              function(actionObject) {
                                                return _c(
                                                  "b-form-radio",
                                                  {
                                                    key: actionObject.value,
                                                    staticClass: "mb-1",
                                                    attrs: {
                                                      "aria-describedby": ariaDescribedbyAction,
                                                      name: "action",
                                                      disabled:
                                                        actionObject.disabled,
                                                      value: actionObject.value
                                                    },
                                                    model: {
                                                      value: _vm.action,
                                                      callback: function($$v) {
                                                        _vm.action = $$v
                                                      },
                                                      expression: "action"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        actionObject.text
                                                      ) + " "
                                                    )
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      564641680
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mb-4" },
                            [
                              _c("b-col", { attrs: { cols: "4" } }, [
                                _c("label", { attrs: { for: "value-name" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("modals.createContext.value")
                                      ) +
                                      " "
                                  )
                                ])
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("b-form-group", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function({
                                                ariaDescribedbyValue
                                              }) {
                                                return [
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass:
                                                        "mb-1 text-uppercase",
                                                      attrs: {
                                                        "aria-describedby": ariaDescribedbyValue,
                                                        name: "source",
                                                        value: "personalization"
                                                      },
                                                      model: {
                                                        value: _vm.source,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.source = $$v
                                                        },
                                                        expression: "source"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "modals.createContext.source.personalization"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass:
                                                        "mb-1 text-uppercase",
                                                      attrs: {
                                                        "aria-describedby": ariaDescribedbyValue,
                                                        name: "source",
                                                        disabled: "",
                                                        value: "localStorage"
                                                      },
                                                      model: {
                                                        value: _vm.source,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.source = $$v
                                                        },
                                                        expression: "source"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "modals.createContext.source.localStorage"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass:
                                                        "text-uppercase",
                                                      attrs: {
                                                        "aria-describedby": ariaDescribedbyValue,
                                                        name: "source",
                                                        disabled: "",
                                                        value: "urlParameter"
                                                      },
                                                      model: {
                                                        value: _vm.source,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.source = $$v
                                                        },
                                                        expression: "source"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "modals.createContext.source.urlParams"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3518797721
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "green-button-transparent border-0 text-uppercase",
                                      on: { click: _vm.createContextObject }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("buttons.select")) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : !_vm.contextObject
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "new-value-box px-3 py-3 mb-4" },
                          [
                            _c(
                              "b-row",
                              [
                                _c("b-col", { attrs: { cols: "12" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn px-4 green-button-transparent border-0 btn-secondary text-center",
                                          on: {
                                            click: function($event) {
                                              _vm.showAddContextSection = true
                                            }
                                          }
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass: "mr-2 top--3",
                                            attrs: {
                                              icon: "plus-circle-fill",
                                              "aria-hidden": "true",
                                              scale: "1"
                                            }
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "settings.page.context.newButton"
                                                )
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("p", {
                                    staticClass: "mb-0",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "settings.page.context.newDescModal"
                                        )
                                      )
                                    }
                                  })
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _c("div", { staticClass: "wrapper-box" }, [
                        _c(
                          "h4",
                          { staticClass: "text-uppercase" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("actionMapping")(
                                    _vm.contextObject.action,
                                    _vm.availableActions
                                  )
                                ) +
                                " "
                            ),
                            _c(
                              "b-dropdown",
                              {
                                attrs: {
                                  "toggle-class": "dot-button px-0 pt-0",
                                  "no-caret": "",
                                  dropup: "",
                                  "no-flip": "",
                                  left: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "button-content",
                                    fn: function() {
                                      return [
                                        _c("b-icon", {
                                          staticStyle: { color: "#000" },
                                          attrs: {
                                            icon: "three-dots-vertical",
                                            "aria-hidden": "true",
                                            scale: ".8"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              },
                              [
                                _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.removeContext } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "modals.deleteTeamItem.dropdown"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.contextObject.cases.or.length !== 0
                          ? _c(
                              "div",
                              [
                                _vm._l(_vm.contextObject.cases.or, function(
                                  orCase,
                                  orIndex
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: `${_vm.contextObject.action}-or-${orIndex}`,
                                      staticClass: "or-context mb-3"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-block and-wrapper p-3"
                                        },
                                        [
                                          _vm._l(orCase.and, function(
                                            andCase,
                                            andIndex
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: `${_vm.contextObject.action}-or-${orIndex}-and-${andIndex}`,
                                                staticClass:
                                                  "and-box box p-1 pl-3 mb-3 text-white"
                                              },
                                              [
                                                andCase[
                                                  Object.keys(andCase)[0]
                                                ][0].var ===
                                                "personalization_set_id"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " ..." +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "settings.page.context.condition.if"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              `settings.page.context.condition.item`
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        " ..." +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "settings.page.context.condition.if"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              `settings.page.context.condition.source.${_vm.contextObject.source}`
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "settings.page.context.condition.value"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("printName")(
                                                              andCase[
                                                                Object.keys(
                                                                  andCase
                                                                )[0]
                                                              ][0].var,
                                                              _vm.personalizationValues
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]),
                                                andCase[
                                                  Object.keys(andCase)[0]
                                                ][0].var ===
                                                "personalization_set_id"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              `settings.page.context.condition.operatorSet.${
                                                                Object.keys(
                                                                  andCase
                                                                )[0]
                                                              }`
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getSetName(
                                                              andCase[
                                                                Object.keys(
                                                                  andCase
                                                                )[0]
                                                              ][1]
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              `settings.page.context.condition.operator.${
                                                                Object.keys(
                                                                  andCase
                                                                )[0]
                                                              }`
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            andCase[
                                                              Object.keys(
                                                                andCase
                                                              )[0]
                                                            ][1]
                                                          )
                                                        )
                                                      ])
                                                    ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "close",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeElement(
                                                          {
                                                            or: orIndex,
                                                            and: andIndex
                                                          }
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("b-icon", {
                                                      staticClass:
                                                        "top--1 mr-1",
                                                      staticStyle: {
                                                        color: "#000"
                                                      },
                                                      attrs: {
                                                        icon: "x",
                                                        scale: "1.5",
                                                        "aria-hidden": "true"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          }),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "green-button-transparent border-0 text-uppercase",
                                              on: {
                                                click: function($event) {
                                                  return _vm.showItemModal(
                                                    _vm.contextObject.action,
                                                    orIndex
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("buttons.and")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                }),
                                _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "green-button-transparent border-0 text-uppercase",
                                    on: {
                                      click: function($event) {
                                        return _vm.showItemModal(
                                          _vm.contextObject.action
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("buttons.or")) + " "
                                    )
                                  ]
                                )
                              ],
                              2
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "green-button-transparent border-0 text-uppercase",
                                    on: {
                                      click: function($event) {
                                        _vm.showContextItemModal = true
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("buttons.create")) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                      ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: { click: _vm.cancelModal }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: { variant: "primary", block: "" },
                    on: { click: _vm.saveContext }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.showContextItemModal
        ? _c("modal-create-context-item", {
            attrs: {
              "personalization-values": _vm.personalizationValues,
              "personalization-set-data": _vm.personalizationSetData,
              action: _vm.action,
              "or-index": _vm.selectedOrIndex
            },
            on: {
              "create-context": _vm.createContextItem,
              "cancel-modal": function($event) {
                _vm.showContextItemModal = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }