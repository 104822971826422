<template>
  <div class="position-absolute">
    <b-modal
      id="personalization-editor-modal"
      ref="personalization-editor-modal"
      centered
      size="lg"
      footer-class="flex-nowrap"
      no-close-on-backdrop
    >
      <template #modal-header>
        <h3>{{ $t('settings.page.context.title') }}</h3>
      </template>

      <template #default>
        <div class="m-4">
          <div v-if="showAddContextSection">
            <b-row class="mb-4">
              <b-col cols="4">
                <label for="value-name">
                  {{ $t('modals.createContext.operation') }}
                </label>
              </b-col>
              <b-col cols="8">
                <b-form-group v-slot="{ ariaDescribedbyAction }">
                  <b-form-radio
                    v-for="actionObject in availableActions"
                    v-model="action"
                    class="mb-1"
                    :key="actionObject.value"
                    :aria-describedby="ariaDescribedbyAction"
                    name="action"
                    :disabled="actionObject.disabled"
                    :value="actionObject.value">{{ actionObject.text }}
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="4">
                <label for="value-name">
                  {{ $t('modals.createContext.value') }}
                </label>
              </b-col>
              <b-col cols="8">
                <div>
                  <b-form-group v-slot="{ ariaDescribedbyValue }">
                    <b-form-radio
                      v-model="source"
                      class="mb-1 text-uppercase"
                      :aria-describedby="ariaDescribedbyValue"
                      name="source"
                      value="personalization">
                      {{ $t('modals.createContext.source.personalization') }}
                    </b-form-radio>
                    <b-form-radio
                      v-model="source"
                      class="mb-1 text-uppercase"
                      :aria-describedby="ariaDescribedbyValue"
                      name="source"
                      disabled
                      value="localStorage">
                      {{ $t('modals.createContext.source.localStorage') }}
                    </b-form-radio>
                    <b-form-radio
                      v-model="source"
                      class="text-uppercase"
                      :aria-describedby="ariaDescribedbyValue"
                      name="source"
                      disabled
                      value="urlParameter">
                      {{ $t('modals.createContext.source.urlParams') }}
                    </b-form-radio>
                  </b-form-group>
                </div>
                <b-button
                  class="green-button-transparent border-0 text-uppercase"
                  @click="createContextObject"
                >
                  {{ $t('buttons.select') }}
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div v-else-if="!contextObject">
            <div class="new-value-box px-3 py-3 mb-4">
              <b-row>
                <b-col cols="12">
                  <!-- <h3 class="mb-0">{{ $t('settings.page.context.newTitle') }}</h3> -->
                  <div class="mb-4">
                    <b-button
                      class="btn px-4 green-button-transparent border-0 btn-secondary text-center"
                      @click="showAddContextSection = true"
                    >
                      <b-icon
                        icon="plus-circle-fill"
                        class="mr-2 top--3"
                        aria-hidden="true"
                        scale="1"
                      ></b-icon>
                      {{ $t('settings.page.context.newButton') }}
                    </b-button>
                  </div>
                  <p class="mb-0" v-html="$t('settings.page.context.newDescModal')"></p>
                </b-col>
              </b-row>
            </div>
          </div>
          <div v-else class="wrapper-box">
            <h4 class="text-uppercase">
              {{ contextObject.action | actionMapping(availableActions) }}
              <b-dropdown
                toggle-class="dot-button px-0 pt-0"
                no-caret
                dropup
                no-flip
                left
              >
                <template #button-content>
                  <b-icon icon="three-dots-vertical"
                          aria-hidden="true" scale=".8" style="color: #000;"
                  ></b-icon>
                </template>
                <b-dropdown-item
                  @click="removeContext"
                >
                  {{ $t('modals.deleteTeamItem.dropdown') }}
                </b-dropdown-item>
              </b-dropdown>
            </h4>
            <div v-if="contextObject.cases.or.length !== 0">
              <div class="or-context mb-3"
                   v-for="(orCase, orIndex) in contextObject.cases.or"
                   :key="`${contextObject.action}-or-${orIndex}`">
                <div class="d-block and-wrapper p-3">
                  <div v-for="(andCase, andIndex) in orCase.and"
                       :key="`${contextObject.action}-or-${orIndex}-and-${andIndex}`"
                       class="and-box box p-1 pl-3 mb-3 text-white"
                  >
                        <span
                          v-if="andCase[Object.keys(andCase)[0]][0].var
                            === 'personalization_set_id'"
                        >
                        ...{{ $t('settings.page.context.condition.if') }}
                        {{ $t(`settings.page.context.condition.item`) }}
                        </span>
                    <span v-else>
                          ...{{ $t('settings.page.context.condition.if') }}
                        {{ $t(`settings.page.context.condition.source.${contextObject.source}`) }}
                        {{ $t('settings.page.context.condition.value') }}
                        <strong>{{
                            andCase[Object.keys(andCase)[0]][0].var
                              | printName(personalizationValues)
                          }}</strong>
                        </span>
                    <span
                      v-if="andCase[Object.keys(andCase)[0]][0].var
                            === 'personalization_set_id'"
                    >
                        {{
                        $t(
                          `settings.page.context.condition.operatorSet.${Object.keys(andCase)[0]}`,
                        )
                      }}
                          <strong>{{ getSetName(andCase[Object.keys(andCase)[0]][1]) }}</strong>
                        </span>
                    <span v-else>
                        {{
                        $t(
                          `settings.page.context.condition.operator.${Object.keys(andCase)[0]}`,
                        )
                      }}
                        <strong>{{ andCase[Object.keys(andCase)[0]][1] }}</strong>
                        </span>
                    <div class="close"
                         @click="removeElement(
                     { or: orIndex, and: andIndex }
                     )"
                    >
                      <b-icon
                        icon="x"
                        style="color: #000;"
                        scale="1.5"
                        aria-hidden="true"
                        class="top--1 mr-1"
                      ></b-icon>
                    </div>
                  </div>
                  <b-button
                    class="green-button-transparent border-0 text-uppercase"
                    @click="showItemModal(contextObject.action, orIndex)"
                  >
                    {{ $t('buttons.and') }}
                  </b-button>
                </div>
              </div>
              <b-button
                class="green-button-transparent border-0 text-uppercase"
                @click="showItemModal(contextObject.action)"
              >
                {{ $t('buttons.or') }}
              </b-button>
            </div>
            <div v-else>
              <b-button
                class="green-button-transparent border-0 text-uppercase"
                @click="showContextItemModal = true"
              >
                {{ $t('buttons.create') }}
              </b-button>
            </div>
          </div>
        </div>
      </template>

      <template #modal-footer>
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="cancelModal"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="p-3"
          block
          @click="saveContext"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </template>
    </b-modal>
    <modal-create-context-item
      v-if="showContextItemModal"
      :personalization-values="personalizationValues"
      :personalization-set-data="personalizationSetData"
      :action="action"
      :or-index="selectedOrIndex"
      @create-context="createContextItem"
      @cancel-modal="showContextItemModal = false"
    />
  </div>
</template>

<script>
export default {
  name: 'ModalPersonalizationEditor',
  components: {
    ModalCreateContextItem: () => import('@/components/modals/ModalCreateContextItem.vue'),
  },
  props: {
    personalization: {
      type: Array,
      required: false,
      default: null,
    },
    contextData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      contextObject: null,
      showAddContextSection: false,
      showContextItemModal: false,
      disableOkButton: true,
      source: 'personalization',
      action: 'showElement',
      selectedOrIndex: undefined,
      availableActions: [
        {
          text: this.$t('settings.page.context.allActions.showElement'),
          value: 'showElement',
        },
        {
          text: this.$t('settings.page.context.allActions.hideElement'),
          value: 'hideElement',
        },
      ],
    };
  },
  created() {
    this.contextObject = this.contextData;
  },
  mounted() {
    this.$refs['personalization-editor-modal'].show();
  },
  computed: {
    computedDisableButton() {
      if (this.contextObject) {
        return false;
      }
      return this.disableOkButton;
    },
    personalizationSetData() {
      return [
        {
          value: null,
          text: this.$t('settings.page.context.selectPersonalizationSet'),
        },
        ...this.personalization.map((set) => ({
          value: set.personalized_variable_set_id,
          text: set.name,
        })),
      ];
    },
    personalizationValues() {
      // first elements to choose
      return this.personalization.map((set) => ({
        name: set.name,
        setId: set.personalized_variable_set_id,
        values: set.schema,
      }));
    },
  },
  filters: {
    printName(value, personalizationObject) {
      // split value
      const [setId, valueString] = value.split('.');
      // find the valueString inside the personalizationObject
      const setObject = personalizationObject.find((set) => set.setId === Number(setId));
      if (setObject) {
        // find the name of the element
        const itemObject = setObject.values.find((item) => item.id === valueString);
        if (itemObject) return `${setObject.name}:${itemObject.name}`;
      }
      return value;
    },
    actionMapping(value, allActions) {
      const actionName = allActions.find((action) => action.value === value);
      return (actionName) ? actionName.text : value;
    },
  },
  methods: {
    createContextObject() {
      this.contextObject = {
        event: 'onRender',
        action: this.action,
        source: this.source,
        cases: {
          or: [],
        },
      };
      this.showAddContextSection = false;
    },
    disableOkButtonManually(disableButton) {
      if (disableButton) {
        this.disableOkButton = true;
      }
    },
    cancelModal() {
      this.$emit('cancel-modal');
    },
    showItemModal(action, orIndex) {
      this.selectedOrIndex = orIndex;
      this.selectedAction = action;
      this.showContextItemModal = true;
    },
    createContextItem(newContext) {
      // close the modal
      this.showContextItemModal = false;
      const { orIndex } = newContext;
      const operation = {};
      operation[newContext.operator] = [
        { var: newContext.leftValue },
        newContext.rightValue,
      ];
      if (orIndex === undefined) {
        const contextItem = {
          and: [],
        };
        contextItem.and.push(operation);
        // add the item to the end of the relevant context
        this.contextObject.cases.or.push(contextItem);
      } else {
        // and context inside the or context
        this.contextObject.cases.or[orIndex].and.push(operation);
      }
    },
    updateContext(newContext) {
      const contextIndex = this.context.findIndex(
        (context) => context.action === newContext.action,
      );
      const { orIndex } = newContext;
      const operation = {};
      operation[newContext.operator] = [
        { var: newContext.leftValue },
        newContext.rightValue,
      ];
      if (orIndex === undefined) {
        const contextItem = {
          and: [],
        };
        contextItem.and.push(operation);
        // add the item to the end of the relevant context
        this.context[contextIndex].cases.or.push(contextItem);
      } else {
        // and context inside the or context
        this.context[contextIndex].cases.or[orIndex].and.push(operation);
      }
    },
    removeContext() {
      this.contextObject = null;
    },
    removeElement(element) {
      this.contextObject.cases.or[element.or].and.splice(element.and, 1);
      if (this.contextObject.cases.or[element.or].and.length === 0) {
        this.contextObject.cases.or.splice(element.or, 1);
        if (this.contextObject.cases.or.length > 1) {
          this.contextObject.splice(this.contextObject.cases.or[element.or], 1);
        }
      }
    },
    saveContext() {
      // emit save event
      this.$emit('editor-context', this.contextObject);
    },
    getSetName(id) {
      const set = this.personalizationSetData.find((element) => element.value === id);
      if (set) return set.text;
      return id;
    },
  },
};
</script>

<style scoped lang="scss">
span {
  color: #0d0d0d;
}

/deep/ .modal-dialog {
  .modal-content {
    border: none;

    label {
      color: #6c757d;
    }
  }

  .custom-control-input ~ .custom-control-label {
    color: #6c757d;
  }

  .custom-control-input[disabled] ~ .custom-control-label {
    cursor: not-allowed;
    color: #bbb;
  }

  button:disabled {
    cursor: not-allowed;
  }
}

.and-wrapper {
  border: 1px solid $site-font-color;
}

.box {
  // background-color: #53575B;
}

.and-box{
  background-color: #eeeeee;
}

.new-value-box {
  // background-color: #53575B;

  p {
    // color: #fff;
    font-size: 12px;
  }
}
/deep/ .show > .btn-secondary.dot-button.dropdown-toggle,
/deep/ .btn-secondary.dot-button.dropdown-toggle:hover{
  background-color: #fff;
  color: #000;
}

/deep/ .dot-button {
  background-color: transparent;
  border: none;
}

.close {
  cursor: pointer;
}
</style>
